import BaseTextComponent from "@/components/base/el/BaseTextComponent";

export default {
    name: "BaseLi",
    mixins: [BaseTextComponent],
    data() {
        return {
            tag: 'li'
        }
    },
}
